import React, { useState } from "react";
import { Box, Container, styled, Typography } from "@mui/material";
import { Link, useParams, useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { useFormik } from "formik";
import * as Yup from "yup";

// custom imports

// components
import FormInput from "../forms/FormInput";
import AppAlert from "../components/AppAlert";

// api
import usersApi from "../api/users";

// utilities
import { handleError } from "../utilities/apiActivity";

const Form = styled(Box)(() => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
  rowGap: "15px",
}));

const Screen = styled(Container)(() => ({
  alignItems: "center",
  display: "flex",
  flex: 1,
  justifyContent: "center",
  padding: "10px",
}));

const StyledBox = styled(Box)(({ theme }) => ({
  alignItems: "center",
  borderRadius: theme.shape.borderRadius,
  display: "flex",
  flex: 1,
  flexDirection: "column",
  justifyContent: "center",
  maxWidth: "400px",
  overflow: "hidden",
  padding: "10px",
  width: "100%",
}));

const SignupButton = styled(Link)(() => ({
  color: "blue",
  fontSize: "1rem",
  marginBottom: "20px",
  marginTop: "20px",
  textAlign: "center",
  textDecoration: "none",
}));

const Title = styled(Typography)(() => ({
  marginBottom: "30px",
  textAlign: "center",
  fontWeight: "bold",
}));

function ResetPassword() {
  const [resetting, setResetting] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
  });

  const { resetToken } = useParams();
  const navigate = useNavigate();

  const handleHideAlert = () => setAlert({ ...alert, open: false });

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .max(16)
        .min(8)
        .required()
        .label("New Password")
        .matches(
          "^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=]).*$",
          "Password should be minimum eight characters, at least one uppercase and one lowercase letter, one number and one special character"
        ),

      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Password must match")
        .required()
        .label("Confirm Password"),
    }),
    onSubmit: (values) => {
      resetPassword(values);
    },
    validateOnBlur: false,
  });

  const resetPassword = async (values) => {
    setResetting(true);
    try {
      await usersApi.resetPassword(values, resetToken);
      setResetting(false);
      formik.resetForm();
      return navigate("/login", { replace: true });
    } catch (error) {
      handleError(error, () => setResetting(false), setAlert);
    }
  };

  return (
    <>
      <Screen>
        <StyledBox>
          <Title gutterBottom variant="h5">
            Create new Password
          </Title>
          <Form component={"form"} onSubmit={formik.handleSubmit}>
            <FormInput
              formik={formik}
              name="newPassword"
              type="password"
              label="New Password"
            />
            <FormInput
              formik={formik}
              name="confirmPassword"
              type="password"
              label="Confirm Password"
            />
            <LoadingButton
              loading={resetting}
              size="small"
              disableElevation
              type="submit"
              variant="contained"
              sx={{ width: "40%" }}
            >
              Submit
            </LoadingButton>
          </Form>
          <SignupButton to="/login" variant="link">
            Login
          </SignupButton>
        </StyledBox>
      </Screen>
      <AppAlert alert={alert} onClose={handleHideAlert} />
    </>
  );
}

export default ResetPassword;
