import React from "react";
import { Box, styled, TextField, IconButton, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Edit, SaveOutlined } from "@mui/icons-material";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";

const Container = styled(Box)(({ theme }) => ({
  alignItems: "flex-start",
  display: "flex",
  gap: "5px",
  justifyContent: "flex-start",
  marginBottom: "20px",
  width: "100%",
  [theme.breakpoints.down(400)]: {
    flexWrap: "wrap",
  },
}));

function EditableInput({
  style,
  label,
  type,
  change,
  setChange,
  formik,
  name,
  disabled = false,
}) {
  const { processing, editMode } = change;

  const handleEditClick = () => {
    if (!editMode) return setChange({ ...change, editMode: true });
    formik.handleSubmit();
  };

  const handleCancelEditing = () => {
    formik.resetForm();
    setChange({ ...change, editMode: false, error: "" });
  };

  return (
    <Container sx={style} variant="div">
      <TextField
        InputProps={{
          disabled: !editMode,
        }}
        error={formik.touched[name] && formik.errors[name] ? true : false}
        value={formik.values[name] || (type === "number" ? Number("") : "")}
        onChange={(e) => formik.setFieldValue(name, e.target.value)}
        type={type}
        fullWidth
        onBlur={formik.handleBlur}
        id={name}
        size="small"
        label={label}
        helperText={formik.touched[name] ? formik.errors[name] : ""}
      />
      <Stack direction={{ xs: "row", sm: "row" }} spacing={1}>
        <LoadingButton
          disableElevation
          disabled={disabled}
          size="small"
          color="secondary"
          loading={processing === true ? true : undefined}
          loadingPosition="start"
          startIcon={
            !editMode ? (
              <Edit fontSize="small" />
            ) : (
              <SaveOutlined fontSize="small" />
            )
          }
          variant="contained"
          onClick={handleEditClick}
        >
          {editMode === true ? "Save" : "Edit"}
        </LoadingButton>
        {editMode ? (
          <IconButton
            variant="contained"
            size="small"
            disabled={processing}
            onClick={handleCancelEditing}
            color="warning"
          >
            <CancelPresentationIcon fontSize="small" />
          </IconButton>
        ) : null}
      </Stack>
    </Container>
  );
}

export default EditableInput;
