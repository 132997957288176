import React, { useState } from "react";
import {
  Box,
  Typography,
  styled,
  Collapse,
  TextField,
  Divider,
  Stack,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { handleError } from "../utilities/apiActivity";
import AppAlert from "./AppAlert";

const MainContainer = styled(Box)(({ theme }) => ({
  border: "1px solid lightgray",
  borderRadius: theme.shape.borderRadius,
  display: "flex",
  flex: 1,
  flexDirection: "column",
  padding: "5px 10px",
  backgroundColor: "lightgray",
  boxShadow: theme.shadows[4],
}));

const Header = styled(Box)(() => ({
  alignItems: "center",
  display: "flex",
  flexWrap: "wrap",
  gap: "5px",
  justifyContent: "space-between",
  padding: "5px 0",
}));

const DataInfo = styled(Typography)(() => ({
  fontSize: "14px",
}));

const DataValue = styled(Typography)(() => ({
  fontSize: "0.9rem",
  fontWeight: "500",
  wordBreak: "break-word",
  wordWrap: "break-word",
}));

const Title = styled(Typography)(() => ({
  fontSize: "14px",
  fontWeight: "500",
  textDecoration: "underline",
}));

const VerificationWrapper = styled(Collapse)(({ theme }) => ({
  alignItems: "center",
  borderRadius: theme.shape.borderRadius,
  display: "flex",
}));

const VerificationContainer = styled(Box)(() => ({
  alignItems: "center",
  display: "flex",
  flexWrap: "wrap",
  gap: "5px",
  justifyContent: "space-between",
}));

function VerifyCredential({
  title,
  data,
  getTokenFunction,
  verifyTokenFunction,
  onSuccesfullVerification = () => {},
  additionalInfo = "",
}) {
  const [open, setOpen] = useState(false);
  const [sendingRequest, setSendingRequest] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");

  const [alert, setAlert] = useState({
    message: "",
    open: false,
  });

  const handleCloseAlert = () => setAlert({ ...alert, open: false });

  const handleCodeInput = (e) => {
    setVerificationCode(e.target.value);
  };

  const getCode = async () => {
    setSendingRequest(true);
    try {
      await getTokenFunction();
      setSendingRequest(false);
      setAlert({ open: true, message: "Verification code sent!" });
      return setOpen(true);
    } catch (error) {
      handleError(error, () => setSendingRequest(false), setAlert);
    }
  };

  const verifyCode = async () => {
    setVerifying(true);
    try {
      let { data } = await verifyTokenFunction({ verificationCode });
      setVerifying(false);
      onSuccesfullVerification(data);
      return setOpen(false);
    } catch (error) {
      handleError(error, () => setVerifying(false), setAlert);
    }
  };

  return (
    <>
      <MainContainer>
        <Title>{title}</Title>
        <Header>
          <DataInfo>
            {data.toVerify} :{" "}
            <DataValue component="span">{data.value}</DataValue>
          </DataInfo>
          <LoadingButton
            loading={sendingRequest && !open}
            variant="contained"
            size="small"
            disableElevation
            color="primary"
            disabled={data.isVerified || open}
            onClick={getCode}
          >
            {data.isVerified ? "Verified" : "Verify now"}
          </LoadingButton>
        </Header>
        <VerificationWrapper in={open}>
          <Divider sx={{ m: "10px 0" }} />
          {additionalInfo ? (
            <DataInfo
              sx={{
                backgroundColor: "Highlight",
                marginBottom: "10px",
                padding: "5px",
                borderRadius: "5px",
                color: "white",
              }}
            >
              {additionalInfo}
            </DataInfo>
          ) : null}
          <VerificationContainer>
            <TextField
              label="Enter verification code"
              value={verificationCode.trim()}
              onChange={handleCodeInput}
              size="small"
              type="text"
            />
            <Stack
              direction={{ xs: "row", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
            >
              <LoadingButton
                loading={verifying}
                variant="contained"
                size="small"
                disableElevation
                onClick={verifyCode}
                disabled={
                  verificationCode.trim().length < 6 ||
                  verificationCode.trim().length > 6
                }
              >
                Verify
              </LoadingButton>
              <LoadingButton
                loading={sendingRequest}
                variant="contained"
                size="small"
                disableElevation
                color="secondary"
                disabled={!open}
                onClick={getCode}
              >
                Resend Code
              </LoadingButton>
            </Stack>
          </VerificationContainer>
        </VerificationWrapper>
      </MainContainer>
      <AppAlert alert={alert} onClose={handleCloseAlert} />
    </>
  );
}

export default VerifyCredential;
