import React from "react";
import { Snackbar, Alert } from "@mui/material";
import Slide from "@mui/material/Slide";

let positions = {
  topRight: { vertical: "top", horizontal: "right" },
  topCenter: { vertical: "top", horizontal: "center" },
  topLeft: { vertical: "top", horizontal: "left" },
  bottomLeft: { vertical: "bottom", horizontal: "left" },
  bottomRight: { vertical: "bottom", horizontal: "right" },
  bottomCenter: { vertical: "bottom", horizontal: "center" },
};

function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}

function AppAlert({ alert, onClose, position = "topCenter" }) {
  return (
    <Snackbar
      anchorOrigin={positions[position]}
      open={alert.open}
      TransitionComponent={TransitionLeft}
      autoHideDuration={5000}
      onClose={onClose}
    >
      <Alert
        variant="filled"
        onClose={onClose}
        severity={alert.type || "success"}
        sx={{ width: "100%" }}
      >
        {alert.message}
      </Alert>
    </Snackbar>
  );
}

export default AppAlert;
