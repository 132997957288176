import apiClient from "./apiClient";

const endPoint = "/api/users";

const registerUser = (data) => apiClient.post(endPoint + "/register", data);

const loginUser = (data) => apiClient.post(endPoint + "/login", data);

const getCurrentUser = () => apiClient.get(endPoint + "/me", {});

const logoutUser = () => apiClient.get(endPoint + "/logout", {});

const loginOauth = (data) =>
  apiClient.post(endPoint + "/new/gmail/login", data);

const updateAvatar = (file) => {
  const formData = new FormData();

  formData.append("avatar", file);

  const config = { headers: { "Content-Type": "multipart/form-data" } };

  return apiClient.put(endPoint + "/update/avatar", formData, config);
};

const updatePassword = (values) =>
  apiClient.put(endPoint + "/update/password", values);

const getForgotPasswordToken = (data) =>
  apiClient.post(endPoint + "/password/forgot", data);

const resetPassword = (data, token) =>
  apiClient.put(endPoint + "/password/reset/" + token, data);

const getEmailToken = () => apiClient.post(endPoint + "/email/getCode", {});

const verifyEmailToken = (data) =>
  apiClient.post(endPoint + "/email/verifyCode", data);

const usersApi = {
  registerUser,
  loginUser,
  getCurrentUser,
  logoutUser,
  loginOauth,
  updateAvatar,
  updatePassword,
  getForgotPasswordToken,
  resetPassword,
  getEmailToken,
  verifyEmailToken,
};

export default usersApi;
