import React from "react";
import {
  Container,
  Box,
  Typography,
  Button,
  Grid,
  styled,
} from "@mui/material";
import AppImage from "./AppImage";

const GridItemWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  color: theme.palette.primary.main,
  fontWeight: "bold",
}));
const Title = styled(Typography)(({ theme }) => ({
  fontSize: "28px",
  color: "black",
  fontWeight: "bold",
  lineHeight: "32px",
  margin: "10px 0",
}));
const Info = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  color: "grey",
}));

const customStyle = {
  alignSelf: { xs: "center", sm: "flex-start" },
  textAlign: { xs: "center", sm: "left" },
};

export default function Hero({ onButtonClick = () => {} }) {
  return (
    <Container sx={{ margin: "50px 0", alignSelf: "center" }}>
      <Grid container spacing={5}>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "center", sm: "flex-end" },
          }}
          item
          xs={12}
          sm={6}
        >
          <AppImage
            style={{ borderRadius: "5px" }}
            defaultImage={require("../assets/heroimageo.png")}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "center", sm: "flex-start" },
            justifyContent: { xs: "flex-start", sm: "center" },
          }}
        >
          <GridItemWrapper sx={{ maxWidth: "300px" }}>
            <SubTitle marginTop={2} sx={customStyle} component="h1">
              RESEARCH IS FASTER & BETTER
            </SubTitle>
            <Title sx={customStyle} component="h2">
              Generate User Persona with AI
            </Title>
            <Info sx={customStyle} component="h6">
              Use AI to generate User persona for your new project and speed up
              your ideation phase
            </Info>

            <Button
              color="primary"
              disableElevation
              size="small"
              onClick={onButtonClick}
              variant="contained"
              sx={{
                margin: "10px 0 0 0",
                alignSelf: { xs: "center", sm: "flex-start" },
              }}
            >
              Start Generating For Free
            </Button>
          </GridItemWrapper>
        </Grid>
      </Grid>
    </Container>
  );
}
