import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PageHeader from "../components/PageHeader";
import { Container, Grid, styled, Box, Divider } from "@mui/material";
import ScrollUp from "../components/ScrollUp";
import { Helmet } from "react-helmet-async";
import useAuth from "../auth/useAuth";
import EditableInput from "../components/EditableInput";
import AppImage from "../components/AppImage";
import defaultImages from "../data/defaultImages";
import { LoadingButton } from "@mui/lab";
import { Delete, Update, Upload } from "@mui/icons-material";
import regex from "../data/regex";
import { useFormik } from "formik";
import * as Yup from "yup";
import SectionTitle from "../components/Section/SectionTitle";
import FormInput from "../forms/FormInput";
import usersApi from "../api/users";
import AppAlert from "../components/AppAlert";
import { handleError } from "../utilities/apiActivity";

const GridItemContainer = styled(Box)(() => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: "10px 10px",
}));

const EditAvatarContainer = styled(Box)(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  gap: "5px",
  justifyContent: "center",
  [theme.breakpoints.down(300)]: {
    flexWrap: "wrap",
  },
}));

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: "500px",
  padding: "10px 10px",
}));

const Form = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
  rowGap: "10px",
}));

export default function Profile() {
  const navigate = useNavigate();
  const [updatingAvatar, setUpdatingAvatar] = useState(false);
  const [removingAvatar, setRemovingAvatar] = useState(false);
  const { user, setUser } = useAuth();

  const [nameChange, setNameChange] = useState({
    processing: false,
    editMode: false,
  });
  const [emailChange, setEmailChange] = useState({
    processing: false,
    editMode: false,
  });

  const [alert, setAlert] = useState({
    open: false,
    message: "",
  });
  const hideAlert = () => setAlert({ open: false, message: "" });

  const [updatingPassword, setUpdatingPassword] = useState(false);

  const updatePassword = async (values) => {
    setUpdatingPassword(true);
    try {
      const { data } = await usersApi.updatePassword(values);
      setUser(data.user);
      formik.resetForm();
      setAlert({ open: true, message: "Password updated successfully." });
      return setUpdatingPassword(false);
    } catch (error) {
      handleError(error, () => setUpdatingPassword(false), setAlert);
    }
  };

  const handleUpdateAvatar = async (file) => {
    if (file) {
      setUpdatingAvatar(true);
    } else if (!file) {
      setRemovingAvatar(true);
    }
    try {
      const { data } = await usersApi.updateAvatar(file);
      setUser(data);
      setAlert({
        open: true,
        message: "Profile picture updated successfully!",
      });
      if (file) {
        return setUpdatingAvatar(false);
      } else if (!file) {
        return setRemovingAvatar(false);
      }
    } catch (error) {
      handleError(
        error,
        () => {
          if (file) {
            setUpdatingAvatar(false);
          } else if (!file) {
            setRemovingAvatar(false);
          }
        },
        setAlert
      );
    }
  };

  const formikName = useFormik({
    initialValues: {
      name: user ? user.firstName + " " + user.lastName : "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .matches(regex.username, { message: "Invalid name" })
        .max(30, "Cannot be more than 30 characters")
        .min(4, "Name must be atleast 4 characters long.")
        .required()
        .label("Name"),
    }),
    onSubmit: (values) => {},
    validateOnBlur: false,
  });

  const formikEmail = useFormik({
    initialValues: {
      email: user ? user.email : "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required().label("Email").max(150),
    }),
    onSubmit: (values) => {},
    validateOnBlur: false,
  });

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string().max(32).min(8).required().label("New Password"),

      confirmPassword: Yup.string()
        .max(32)
        .min(8)
        .required()
        .label("Confirm Password")
        .oneOf([Yup.ref("newPassword")], "Passwords do not match"),

      oldPassword: Yup.string().max(32).required().label("Old Password"),
    }),
    onSubmit: (values) => updatePassword(values),
    validateOnBlur: false,
  });

  return (
    <>
      <Helmet>
        <title>Predesign - Profile</title>
      </Helmet>
      <PageHeader
        title={`Hi! ${user.firstName}`}
        subTitle="Welcome to PREDESIGN"
      />
      <Container sx={{ margin: "20px 0 50px 0", alignSelf: "center" }}>
        <Grid container>
          <Grid
            item
            sx={{ display: "flex", justifyContent: "center" }}
            xs={12}
            sm={!user.zeroAuthLogged ? 5 : 12}
          >
            <GridItemContainer
              sx={{ gap: "10px", maxWidth: "400px", alignSelf: "center" }}
            >
              <AppImage
                style={{
                  borderRadius: "20px",
                  boxShadow: (theme) => theme.shadows[4],
                  width: { xs: "80%", sm: "60%", md: "40%" },
                }}
                defaultImage={defaultImages.DEFAULT_IMAGE}
                imageUrl={user ? user.avatar_url : ""}
              />

              <EditAvatarContainer>
                <LoadingButton
                  loading={updatingAvatar}
                  loadingPosition="start"
                  startIcon={<Upload />}
                  disableElevation
                  color="primary"
                  size="small"
                  variant="contained"
                  component="label"
                >
                  Update
                  <input
                    hidden
                    onChange={(e) => handleUpdateAvatar(e.target.files[0])}
                    accept="image/*"
                    type="file"
                  />
                </LoadingButton>
                {user.avatar_url ? (
                  <LoadingButton
                    loading={removingAvatar}
                    loadingPosition="start"
                    startIcon={<Delete />}
                    size="small"
                    disableElevation
                    color="warning"
                    variant="contained"
                    onClick={() => handleUpdateAvatar("")}
                  >
                    Remove
                  </LoadingButton>
                ) : null}
              </EditAvatarContainer>

              <EditableInput
                disabled={true}
                change={nameChange}
                label="Name"
                type="text"
                name="name"
                //setChange={setNameChange}
                formik={formikName}
              />
              <EditableInput
                disabled={true}
                change={emailChange}
                label="Email"
                type="email"
                //setChange={setEmailChange}
                name="email"
                formik={formikEmail}
              />
            </GridItemContainer>
          </Grid>
          {user.zeroAuthLogged ? null : (
            <Grid
              sx={{ display: "flex", justifyContent: "center" }}
              item
              xs={12}
              sm={7}
            >
              <Wrapper sx={{ padding: 0, mt: "50px", maxWidth: "400px" }}>
                <SectionTitle>Update Password</SectionTitle>

                <Divider sx={{ marginTop: "5px", marginBottom: "15px" }} />

                <Form component={"form"} onSubmit={formik.handleSubmit}>
                  <FormInput
                    autoComplete={"false"}
                    formik={formik}
                    name="oldPassword"
                    type="password"
                    label="Old Password"
                  />
                  <FormInput
                    formik={formik}
                    name="newPassword"
                    type="password"
                    label="New Password"
                  />
                  <FormInput
                    formik={formik}
                    name="confirmPassword"
                    type="password"
                    label="Confirm Password"
                  />
                  <LoadingButton
                    disableElevation
                    loading={updatingPassword}
                    loadingPosition={"start"}
                    startIcon={<Update />}
                    variant="contained"
                    color="primary"
                    size="small"
                    sx={{ alignSelf: "flex-end" }}
                    onClick={formik.handleSubmit}
                  >
                    Update
                  </LoadingButton>
                </Form>
              </Wrapper>
            </Grid>
          )}
        </Grid>
      </Container>
      <ScrollUp />
      <AppAlert alert={alert} onClose={hideAlert} />
    </>
  );
}
