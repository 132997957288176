import React from "react";
import { Box, TextField } from "@mui/material";

function FormInput(props) {
  const {
    label,
    name,
    type,
    formik,
    style,
    disabled,
    fullWidth = true,
    placeholder = "",
    autoComplete = "true",
  } = props;

  return (
    <Box sx={style} variant="div">
      <TextField
        error={formik.touched[name] && formik.errors[name] ? true : false}
        value={formik.values[name] || (type == "number" ? Number("") : "")}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        id={name}
        disabled={disabled}
        autoComplete={(autoComplete === "true").toString()}
        name={name}
        type={type}
        fullWidth={fullWidth}
        inputProps={{
          style: {
            backgroundColor: "white",
            borderRadius: "5px",
            fontFamily: "Lato, sans-serif",
          },
        }}
        InputLabelProps={{
          style: {
            fontFamily: "Lato, sans-serif",
          },
        }}
        size="small"
        label={label}
        helperText={formik.touched[name] ? formik.errors[name] : ""}
        placeholder={placeholder}
      />
    </Box>
  );
}

export default FormInput;
