import React, { useContext, useState } from "react";
import {
  Typography,
  Container,
  styled,
  Box,
  Button,
  Slider,
  Dialog,
  DialogActions,
  useTheme,
  useMediaQuery,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  Grid,
  IconButton,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
import { WhatsappShareButton, WhatsappIcon } from "react-share";

import { useFormik } from "formik";
import * as Yup from "yup";

import FormSelect from "../forms/FormSelect";
import FormInput from "../forms/FormInput";
import {
  countries,
  ethnicity,
  familyStatus,
  maritalStatus,
} from "../data/personaDetails";
import { LoadingButton } from "@mui/lab";
import ScrollUp from "../components/ScrollUp";
import { Helmet } from "react-helmet-async";
import personasApi from "../api/personas";
import { handleError } from "../utilities/apiActivity";
import AppAlert from "../components/AppAlert";
import PersonalDetails from "../components/PersonalDetails";
import InteractionHistoryContext from "../context/interactionHistoryContext";
import { Close } from "@mui/icons-material";

const Wrapper = styled(Container)(() => ({
  display: "flex",
  flexDirection: "column",
  margin: "20px 0 50px 0",
}));

const NavTitleSource = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: "bold",
  fontSize: "12px",
  // cursor: "pointer",
}));

const NavTitleCurrent = styled(Typography)(() => ({
  color: "black",
  fontWeight: "bold",
  fontSize: "12px",
}));

const Title = styled(Typography)(() => ({
  color: "black",
  fontWeight: "bold",
  fontSize: "18px",
}));

const DetailsContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "rgba(46, 22, 230, 0.06)",
  padding: "10px",
  borderRadius: "10px",
  marginTop: "20px",
  justifyContent: "center",
}));

const DetailsSubContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  padding: "5px 0",
  columnGap: "10px",
  rowGap: "10px",
  flexWrap: "wrap",
}));

const CustomInputContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  borderRadius: "5px",
  backgroundColor: "white",
  alignSelf: "flex-start",
  width: "100%",
  padding: "5px 10px",
  border: "1px solid lightgrey",
}));

export default function PersonalGenerator() {
  const [generating, setGenerating] = useState(false);
  const { interactionHistory, setInteractionHistory } = useContext(
    InteractionHistoryContext
  );
  const [savingPersona, setSavingPersona] = useState(false);
  const [openDialog, setOpenDialog] = useState({
    persona: null,
    open: false,
  });

  const [openFormDialog, setOpenFormDialog] = useState(false);

  const handleSubmitFeedbackClick = () => {
    setOpenFormDialog(false);
    setInteractionHistory(true);
    window.open("https://forms.gle/bJ3Lny1y9pWMZ5zd7", "_blank");
  };

  const closeFormDialog = () => setOpenFormDialog(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [alert, setAlert] = useState({
    open: false,
    message: "",
  });
  const hideAlert = () => setAlert({ open: false, message: "" });

  const handleGeneratePersona = async (values) => {
    setGenerating(true);
    try {
      const { data } = await personasApi.generatePersona(values);
      setGenerating(false);
      console.log(data);
      setOpenDialog({
        open: true,
        persona: data,
      });
    } catch (error) {
      handleError(error, () => setGenerating(false), setAlert);
    }
  };

  const handleResetClick = () => {
    formik.resetForm();
  };

  const closeDialog = () => {
    if (!interactionHistory) {
      setOpenFormDialog(true);
    }
    setOpenDialog({ open: false, persona: null });
  };

  const handleChange = (event, newValue) => {
    formik.setFieldValue("ageLowerLimit", newValue[0]);
    formik.setFieldValue("ageUpperLimit", newValue[1]);
  };

  const formik = useFormik({
    initialValues: {
      background: "",
      role: "",
      gender: "",
      maritalStatus: "",
      familyStatus: "",
      demographics: "",
      ethnicity: "",
      product: "",
      ageLowerLimit: 14,
      ageUpperLimit: 50,
    },
    validationSchema: Yup.object({
      background: Yup.string().required().label("Persona Background"),
      role: Yup.string().required().label("Persona Role"),
      gender: Yup.string().label("Gender"),
      maritalStatus: Yup.string().label("Marital Status"),
      familyStatus: Yup.string().label("Family Status"),
      demographics: Yup.string().label("Demographics"),
      ethnicity: Yup.string().label("Ethnicity"),
      product: Yup.string().label("Name of Product/Service"),
      ageUpperLimit: Yup.number().label("Age Upper Limit"),
      ageLowerLimit: Yup.number().label("Age Lower Limit"),
    }),
    validateOnBlur: false,
    onSubmit: (values) => handleGeneratePersona(values),
  });

  const savePersonaDetails = async () => {
    let persona = openDialog.persona;
    setSavingPersona(true);
    try {
      await personasApi.savePersona(persona);
      setAlert({
        message: "Persona saved successfully!",
        open: true,
      });
      setSavingPersona(false);
    } catch (error) {
      handleError(error, () => setSavingPersona(false), setAlert);
    }
  };

  return (
    <>
      <Helmet>
        <title>Tools - Persona Generator</title>
      </Helmet>
      <Wrapper>
        <NavTitleSource>
          Tools {">"}
          <NavTitleCurrent component="span"> Persona Generator</NavTitleCurrent>
        </NavTitleSource>
        <DetailsContainer onSubmit={formik.handleSubmit} component="form">
          <DetailsSubContainer sx={{ justifyContent: "space-between" }}>
            <Title>Persona Basic Details</Title>
            <Button
              disabled={generating}
              size="small"
              variant="contained"
              color="secondary"
              onClick={handleResetClick}
              sx={{ fontWeight: "bold" }}
              disableElevation
            >
              Reset Details
            </Button>
          </DetailsSubContainer>
          <DetailsSubContainer>
            <Grid container spacing={"10px"}>
              <Grid item xs={12} sm={6} md={4}>
                <FormInput
                  disabled={generating}
                  label="Persona Background"
                  formik={formik}
                  name="background"
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormInput
                  disabled={generating}
                  label="Persona Role"
                  formik={formik}
                  name="role"
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormInput
                  disabled={generating}
                  formik={formik}
                  name="product"
                  type="text"
                  label="Product/Service name (optional)"
                />
              </Grid>
            </Grid>
          </DetailsSubContainer>
          <DetailsSubContainer>
            <Grid container spacing={"10px"}>
              <Grid item xs={12} sm={6} md={4}>
                <CustomInputContainer>
                  <Typography sx={{ marginBottom: "30px", color: "grey" }}>
                    Select Age range (optional)
                  </Typography>
                  <Slider
                    disabled={generating}
                    size="small"
                    getAriaLabel={() => "Agerange"}
                    value={[
                      formik.values.ageLowerLimit,
                      formik.values.ageUpperLimit,
                    ]}
                    onChange={handleChange}
                    valueLabelDisplay="on"
                  />
                </CustomInputContainer>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <CustomInputContainer>
                  <FormControl
                    error={formik.errors["gender"] ? true : false}
                    sx={{ mb: "20px" }}
                  >
                    <FormLabel
                      sx={{
                        fontSize: "16px",
                        fontFamily: "Lato, sans-serif",
                      }}
                      id="demo-row-radio-buttons-group-label"
                    >
                      Gender (optional)
                    </FormLabel>
                    <RadioGroup
                      sx={{ fontSize: "14px" }}
                      onChange={(e) =>
                        formik.setFieldValue("gender", e.target.value)
                      }
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="Female"
                        control={<Radio />}
                        label="Female"
                      />
                      <FormControlLabel
                        value="Male"
                        control={<Radio />}
                        label="Male"
                      />
                      <FormControlLabel
                        value="Prefer not to say"
                        control={<Radio />}
                        label="Prefer not to say"
                      />
                    </RadioGroup>
                  </FormControl>
                </CustomInputContainer>
              </Grid>
            </Grid>
          </DetailsSubContainer>

          <DetailsSubContainer sx={{ margin: "0 0 20px 0" }}>
            <Grid spacing={"10px"} container>
              <Grid item xs={12} sm={6} md={4}>
                <FormSelect
                  isDisabled={generating}
                  label="Demographics (optional)"
                  formik={formik}
                  name="demographics"
                  options={countries}
                  controlStyle={{ minWidth: "200px" }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormSelect
                  isDisabled={generating}
                  label="Ethnicity (optional)"
                  formik={formik}
                  name="ethnicity"
                  options={ethnicity}
                  controlStyle={{ minWidth: "200px" }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormSelect
                  isDisabled={generating}
                  label="Marital Status (optional)"
                  formik={formik}
                  name="maritalStatus"
                  options={maritalStatus}
                  controlStyle={{ minWidth: "200px" }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormSelect
                  isDisabled={generating}
                  label="Family Status (optional)"
                  formik={formik}
                  name="familyStatus"
                  options={familyStatus}
                  controlStyle={{ minWidth: "200px" }}
                />
              </Grid>
            </Grid>
          </DetailsSubContainer>

          <LoadingButton
            size="small"
            variant="contained"
            loading={generating}
            onClick={formik.handleSubmit}
            sx={{ alignSelf: "flex-end" }}
          >
            Generate
          </LoadingButton>
        </DetailsContainer>

        <Dialog
          disableEscapeKeyDown={true}
          onClose={(event, reason) =>
            reason === "backdropClick" ? () => {} : closeDialog
          }
          fullScreen={fullScreen}
          open={openDialog.open}
          aria-labelledby="see-persona-details"
        >
          <Box sx={{ padding: "5px" }}>
            <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
              Generated Persona
            </Typography>
            {openDialog.persona ? (
              <PersonalDetails persona={openDialog.persona} />
            ) : null}
          </Box>
          <DialogActions>
            <Button
              variant="contained"
              autoFocus
              size="small"
              onClick={closeDialog}
            >
              <CloseRoundedIcon fontSize="small" sx={{ mr: "2px" }} /> Close
            </Button>
            <LoadingButton
              variant="contained"
              autoFocus
              size="small"
              onClick={savePersonaDetails}
              loading={savingPersona}
            >
              <FavoriteBorderRoundedIcon fontSize="small" sx={{ mr: "2px" }} />{" "}
              Save
            </LoadingButton>
            <WhatsappShareButton
              title={`Hi check out this AI persona generator which does the work of hours in minutes.`}
              url={`https://predesign.in`}
              separator={"  "}
              style={{
                backgroundColor: "#2E16E6",
                color: "white",
                textTransform: "uppercase",
                fontSize: "10px",
                fontFamily: "Lato, sans-serif",
                display: "flex",
                alignItems: "center",
                padding: "6px 12px",
                borderRadius: "5px",
                columnGap: "2px",
              }}
            >
              <WhatsappIcon round size={20} /> Share
            </WhatsappShareButton>
          </DialogActions>
        </Dialog>
        {/* Form Dialog */}
        <Dialog
          onClose={closeFormDialog}
          open={openFormDialog}
          aria-labelledby="see-form-details"
        >
          <IconButton
            sx={{ alignSelf: "flex-end", m: "5px 5px 0 0" }}
            onClick={closeFormDialog}
            size="small"
          >
            <Close fontSize="small" />
          </IconButton>
          <Box
            sx={{ padding: "40px", display: "flex", flexDirection: "column" }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "18px",
                color: theme.palette.primary.main,
                alignSelf: "center",
                textAlign: "center",
                marginBottom: "10px",
              }}
            >
              Thanks for using PreDesign
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                color: "black",
                textAlign: "center",
                marginBottom: "30px",
              }}
            >
              We are in mission of making this the AI tool compatible with
              designers need. Can you please help us with your feedback so that
              we can work on those shortcomings.
            </Typography>
            <Button
              sx={{ alignSelf: "center" }}
              variant="contained"
              autoFocus
              size="small"
              onClick={handleSubmitFeedbackClick}
            >
              Submit Your Feedback
            </Button>
          </Box>
        </Dialog>
      </Wrapper>
      <AppAlert alert={alert} onClose={hideAlert} />
      <ScrollUp />
    </>
  );
}
