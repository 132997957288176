import React from "react";
import { Container, styled, Typography } from "@mui/material";
import terms from "../data/termsAndConditions";
import { Helmet } from "react-helmet-async";
import ScrollUp from "../components/ScrollUp";

const MainContainer = styled(Container)(() => ({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  padding: "10px 10px 20px 10px",
}));

const Description = styled(Typography)(() => ({
  fontSize: "0.9rem",
  marginTop: "2px",
}));

const Title = styled(Typography)(() => ({
  fontSize: "0.9rem",
  fontWeight: "bold",
  marginTop: "10px",
}));

function TermsOfService() {
  return (
    <>
      <Helmet>
        <title>Predesign - Terms of Service</title>
        <meta name="description" content="Predesign - Terms of Service" />
      </Helmet>
      <MainContainer>
        <Typography
          component="h1"
          sx={{ fontSize: "1rem", margin: "10px 0 20px 0" }}
        >
          Terms and Conditions for Predesign.
        </Typography>
        {terms.map((p) => (
          <Title key={p.id}>
            {p.title}:{" "}
            <Description component="span">{p.description}</Description>
          </Title>
        ))}
      </MainContainer>
      <ScrollUp />
    </>
  );
}

export default TermsOfService;
