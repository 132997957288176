import axios from "axios";

//let baseURL = "";
let baseURL = "https://api-predesign.onrender.com";

const apiClient = axios.create({
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
  baseURL: baseURL,
  timeout: 60000,
  timeoutErrorMessage: "Request timed out",
});

export default apiClient;
