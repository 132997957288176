import React from "react";
import RingLoader from "react-spinners/BarLoader";
import { Box, styled } from "@mui/material";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

const Container = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flex: 1,
  backgroundColor: "white",
}));

const LOADER_COLOR = "#6F009E";

const SplashScreen = ({ style }) => {
  return (
    <Container sx={style}>
      <RingLoader
        color={LOADER_COLOR}
        loading={true}
        cssOverride={override}
        size={50}
      />
    </Container>
  );
};

export default SplashScreen;
