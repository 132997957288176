import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#2E16E6",
    },
    secondary: {
      main: "#FCd469",
    },
  },
  shape: {
    borderRadius: 5,
  },

  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: "Lato , sans-serif",
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          fontFamily: "Lato , sans-serif",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          fontFamily: "Lato , sans-serif",
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontFamily: "Lato , sans-serif",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        sizeSmall: {
          fontSize: 11,
          paddingTop: "6px",
          paddingBottom: "6px",
          fontFamily: "Lato , sans-serif",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontFamily: "Lato , sans-serif",
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        fontFamily: "Lato , sans-serif",
      },
    },
    MuiIcon: {
      defaultProps: {
        fontSize: "small",
      },
    },
  },
});
