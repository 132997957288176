import React from "react";
import { Typography, styled } from "@mui/material";

const StyledText = styled(Typography)(({ theme }) => ({
  fontSize: "13px",
  color: theme.palette.primary.main,
  fontWeight: "bold",
  textAlign: "center",
}));

export default function SectionTitle({ children, styles = {} }) {
  return <StyledText sx={styles}>{children}</StyledText>;
}
