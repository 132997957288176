import React, { useState } from "react";
import { styled, Box, Typography, Divider, Chip } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import { useNavigate, Link } from "react-router-dom";

import FormInput from "../forms/FormInput";
import PageHeader from "../components/PageHeader";
import AppAlert from "../components/AppAlert";
import ScrollUp from "../components/ScrollUp";
import { Helmet } from "react-helmet-async";
import { GoogleLogin } from "@react-oauth/google";
import jwtDecode from "jwt-decode";
import usersApi from "../api/users";

import useAuth from "../auth/useAuth";
import { handleError } from "../utilities/apiActivity";

const Form = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  margin: "20px 0 50px 0",
  maxWidth: "350px",
  alignSelf: "center",
  flex: 1,
  rowGap: "15px",
  width: "100%",
}));

const SignupLink = styled(Typography)(() => ({
  color: "blue",
  fontSize: "1rem",
  textAlign: "center",
  textDecoration: "none",
  fontWeight: "bold",
  cursor: "pointer",
}));

const SignupInfo = styled(Typography)(() => ({
  fontSize: "1rem",
  marginBottom: "20px",
  marginTop: "20px",
  fontWeight: "400",
  textAlign: "center",
}));

export default function Signup() {
  const navigate = useNavigate();
  const [signingUp, setSigningUp] = useState(false);
  const { logIn } = useAuth();
  const [alert, setAlert] = useState({
    message: "",
    open: false,
  });

  const hideAlert = () => setAlert((alert) => ({ ...alert, open: false }));

  const handleSignup = async (values) => {
    setSigningUp(true);

    try {
      const { data } = await usersApi.registerUser(values);
      logIn(data.user);
      setSigningUp(false);
      navigate("/", { replace: true });
    } catch (error) {
      handleError(error, () => setSigningUp(false), setAlert);
    }
  };

  const handleCallbackResponse = async (response) => {
    setSigningUp(true);
    try {
      const { data } = await usersApi.loginOauth(
        jwtDecode(response.credential)
      );
      setSigningUp(false);
      logIn(data.user);
    } catch (error) {
      handleError(error, () => setSigningUp(false), setAlert);
    }
  };

  const handleLoginClick = () => navigate("/login");

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required().max(20).label("First Name"),
      lastName: Yup.string().required().max(20).label("Last Name"),
      email: Yup.string().email().max(150).required().label("Email"),
      password: Yup.string().max(100).required().label("Password"),
    }),
    validateOnBlur: false,
    onSubmit: (values) => handleSignup(values),
  });
  return (
    <>
      <Helmet>
        <title>Predesign - Signup</title>
      </Helmet>
      <PageHeader title="Sign Up" />
      <Form component="form" onSubmit={formik.handleSubmit}>
        <FormInput
          name="firstName"
          type="text"
          label="First Name"
          formik={formik}
        />
        <FormInput
          name="lastName"
          type="text"
          label="last Name"
          formik={formik}
        />
        <FormInput name="email" type="email" label="Email" formik={formik} />
        <FormInput
          name="password"
          type="password"
          label="Password"
          formik={formik}
        />
        <LoadingButton
          variant="contained"
          size="small"
          onClick={formik.handleSubmit}
          loading={signingUp}
          color="primary"
          type="submit"
        >
          Sign Up
        </LoadingButton>
        <Divider sx={{ marginTop: "10px" }}>
          <Chip label="OR" />
        </Divider>
        {/* <AppLink to="/forgotPwd">Forgot Password?</AppLink> */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          id="signinDiv"
        >
          <GoogleLogin
            text="signup_with"
            size="large"
            onSuccess={handleCallbackResponse}
            onError={() => {
              console.log("Login Failed");
            }}
          />
        </Box>
        <SignupInfo>
          Already have an account?{" "}
          <SignupLink component="span" onClick={handleLoginClick}>
            Login
          </SignupLink>
        </SignupInfo>
      </Form>
      <AppAlert alert={alert} onClose={hideAlert} />
      <ScrollUp />
    </>
  );
}
