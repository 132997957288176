import React from "react";
import { Box, styled } from "@mui/material";

const Container = styled(Box)(() => ({
  overflow: "hidden",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

function AppImage({
  imageUrl,
  defaultImage,
  style,
  alt = "image",
  className = {},
  onClick = () => {},
}) {
  return (
    <Container onClick={onClick} sx={style}>
      <img
        className={className}
        alt={alt}
        src={imageUrl ? imageUrl : defaultImage}
        style={{
          objectFit: "contain",
          width: "100%",
          height: "auto",
        }}
      />
    </Container>
  );
}

export default AppImage;
