import React, { useState, useEffect } from "react";
import { styled, Box, Typography, Divider, Chip } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import jwtDecode from "jwt-decode";

import FormInput from "../forms/FormInput";
import PageHeader from "../components/PageHeader";
import ScrollUp from "../components/ScrollUp";
import { Helmet } from "react-helmet-async";
import useAuth from "../auth/useAuth";
import usersApi from "../api/users";
import { handleError } from "../utilities/apiActivity";
import AppAlert from "../components/AppAlert";

const Form = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  margin: "20px 0 50px 0",
  maxWidth: "350px",
  alignSelf: "center",
  flex: 1,
  rowGap: "15px",
  width: "100%",
}));

const AppLink = styled(Link)(() => ({
  color: "black",
  fontSize: "0.8rem",
  textDecoration: "none",
  textAlign: "center",
}));

const SignupLink = styled(Typography)(() => ({
  color: "blue",
  fontSize: "1rem",
  textAlign: "center",
  textDecoration: "none",
  fontWeight: "bold",
  cursor: "pointer",
}));

const SignupInfo = styled(Typography)(() => ({
  fontSize: "1rem",
  marginBottom: "20px",
  marginTop: "20px",
  fontWeight: "400",
  textAlign: "center",
}));

export default function Login() {
  const navigate = useNavigate();
  const { user, logIn } = useAuth();
  const { state } = useLocation();
  const [loggingIn, setLoggingIn] = useState(false);

  const [alert, setAlert] = useState({
    message: "",
    open: false,
  });

  const hideAlert = () => setAlert((alert) => ({ ...alert, open: false }));

  let redirectTo = state ? (state.from ? state.from.pathname : "/") : "/";

  useEffect(() => {
    if (user) {
      return navigate(redirectTo, { replace: true });
    }
  }, [user]);

  const handleCallbackResponse = async (response) => {
    setLoggingIn(true);
    try {
      const { data } = await usersApi.loginOauth(
        jwtDecode(response.credential)
      );
      setLoggingIn(false);
      logIn(data.user);
    } catch (error) {
      handleError(error, () => setLoggingIn(false), setAlert);
    }
  };

  const handleLogin = async (values) => {
    setLoggingIn(true);

    try {
      const { data } = await usersApi.loginUser(values);
      setLoggingIn(false);
      logIn(data.user);
    } catch (error) {
      handleError(error, () => setLoggingIn(false), setAlert);
    }
  };

  const handleSignupClick = () => navigate("/signup");

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email().max(150).required().label("Email"),
      password: Yup.string().max(100).required().label("Password"),
    }),
    validateOnBlur: false,
    onSubmit: (values) => handleLogin(values),
  });
  return (
    <>
      <Helmet>
        <title>Predesign - Login</title>
      </Helmet>
      <PageHeader
        title="Login"
        subTitle="Unlock the full potential of our service with a quick login"
      />
      <Form component="form" onSubmit={formik.handleSubmit}>
        <FormInput name="email" type="email" label="Email" formik={formik} />
        <FormInput
          name="password"
          type="password"
          label="Password"
          formik={formik}
        />
        <LoadingButton
          variant="contained"
          size="small"
          onClick={formik.handleSubmit}
          loading={loggingIn}
          color="primary"
          type="submit"
        >
          Login
        </LoadingButton>
        <AppLink to="/forgotPwd">Forgot Password?</AppLink>

        <Divider sx={{ marginTop: "10px" }}>
          <Chip label="OR" />
        </Divider>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          id="signinDiv"
        >
          <GoogleLogin
            text="continue_with"
            size="large"
            onSuccess={handleCallbackResponse}
            onError={() => {
              console.log("Login Failed");
            }}
          />
        </Box>
        <SignupInfo>
          Do not have an account?{" "}
          <SignupLink component="span" onClick={handleSignupClick}>
            Sign Up
          </SignupLink>
        </SignupInfo>
      </Form>
      <ScrollUp />
      <AppAlert alert={alert} onClose={hideAlert} />
    </>
  );
}
