import React from "react";
import { Container, styled, Typography } from "@mui/material";
import privacyPolicy from "../data/privacyPolicy";
import { Helmet } from "react-helmet-async";
import ScrollUp from "../components/ScrollUp";

const MainContainer = styled(Container)(() => ({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  padding: "10px 10px 20px 10px",
}));

const Description = styled(Typography)(() => ({
  fontSize: "0.9rem",
  marginTop: "2px",
}));

const Title = styled(Typography)(() => ({
  fontSize: "0.9rem",
  fontWeight: "bold",
  marginTop: "10px",
}));

function Privacy() {
  return (
    <>
      <Helmet>
        <title>Predesign - Privacy Policy</title>
        <meta name="description" content="Predesign - Privacy Policy" />
      </Helmet>
      <MainContainer>
        <Typography
          component="h1"
          sx={{ fontSize: "1rem", margin: "10px 0 20px 0" }}
        >
          At Predesign, we are committed to protecting the privacy of our users.
          This privacy policy explains how we collect, use, and share personal
          information when you use the Predesign platform.
        </Typography>

        {privacyPolicy.map((p) => (
          <Title key={p.id}>
            {p.title}:{" "}
            <Description component="span">{p.description}</Description>
          </Title>
        ))}
      </MainContainer>
      <ScrollUp />
    </>
  );
}

export default Privacy;
