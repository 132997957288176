import React from "react";
import { Navigate } from "react-router-dom";
import useAuth from "./auth/useAuth";

export const AuthNavigator = ({ children }) => {
  let { user } = useAuth();

  if (user) {
    return <Navigate to={"/"} replace />;
  } else {
    return children;
  }
};
