import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { styled, ThemeProvider } from "@mui/material/styles";
import { HelmetProvider } from "react-helmet-async";
import { Box } from "@mui/material";
import { GoogleOAuthProvider } from "@react-oauth/google";
import useLocalStorage from "use-local-storage";

import InteractionHistoryContext from "./context/interactionHistoryContext";

import { theme } from "./theme";

import AuthContext from "./auth/context";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
// import Tools from "./pages/Tools";
import { AuthNavigator } from "./AuthNavigator";
import { RequireAuth } from "./RequireAuth";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import PersonalGenerator from "./pages/PersonalGenerator";
import PageNotFound from "./pages/PageNotFound";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import usersApi from "./api/users";
import Profile from "./pages/Profile";
import SavedPersonas from "./pages/SavedPersonas";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";

const Screen = styled(Box)(() => ({
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
}));

const Wrapper = styled(Box)(() => ({
  display: "flex",
  flex: 1,
  flexDirection: "column",
}));

function App() {
  const [user, setUser] = useState(null);
  const [interactionHistory, setInteractionHistory] = useLocalStorage(
    "googleFormSeen",
    false
  );

  const loadCurrentUser = async () => {
    try {
      let { data } = await usersApi.getCurrentUser();
      setUser(data);
    } catch (error) {}
  };

  useEffect(() => {
    loadCurrentUser();
  }, []);
  return (
    <GoogleOAuthProvider clientId="680635231450-6rl08vg244b1e1cvv4pt96hvt28ohir9.apps.googleusercontent.com">
      <HelmetProvider>
        <ThemeProvider theme={theme}>
          <AuthContext.Provider value={{ user, setUser }}>
            <InteractionHistoryContext.Provider
              value={{ interactionHistory, setInteractionHistory }}
            >
              <Screen>
                <Router>
                  <Navbar />
                  <Wrapper>
                    <Routes>
                      <Route path="/" element={<Home />} />
                      {/* <Route path="/tools" element={<Tools />} /> */}
                      <Route path="/login" element={<Login />} />
                      <Route
                        path="/profile"
                        element={
                          <RequireAuth>
                            <Profile />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/saved"
                        element={
                          <RequireAuth>
                            <SavedPersonas />
                          </RequireAuth>
                        }
                      />
                      {/* <Route path="/support_project" element={<Supportus />} /> */}
                      <Route
                        path="/privacy_policy"
                        element={<PrivacyPolicy />}
                      />
                      <Route
                        path="/terms_of_service"
                        element={<TermsOfService />}
                      />
                      <Route
                        path="/persona_generator"
                        element={
                          <RequireAuth>
                            <PersonalGenerator />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/signup"
                        element={
                          <AuthNavigator>
                            <Signup />
                          </AuthNavigator>
                        }
                      />

                      <Route
                        path="/forgotPwd"
                        element={
                          <AuthNavigator>
                            <ForgotPassword />
                          </AuthNavigator>
                        }
                      />
                      <Route
                        path="/password/reset/:resetToken"
                        element={
                          <AuthNavigator>
                            <ResetPassword />
                          </AuthNavigator>
                        }
                      />

                      <Route path="*" element={<PageNotFound />} />
                    </Routes>
                  </Wrapper>
                  <Footer />
                </Router>
              </Screen>
            </InteractionHistoryContext.Provider>
          </AuthContext.Provider>
        </ThemeProvider>
      </HelmetProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
