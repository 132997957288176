const terms = [
  {
    id: 1,
    title: "Use of Predesign",
    description: `You may use Predesign only for lawful purposes
     and in accordance with these Terms. You must be at least 18
      years old to use our app and website.`,
  },
  {
    id: 123,
    title: "Registration",
    description: `To use Predesign, you may be required to 
    register with us by providing your email address and 
    creating a password. You may also sign up with your Google account.`,
  },
  {
    id: 234,
    title: "Intellectual Property",
    description: `All content on Predesign, including text, graphics, 
    logos, images, and software, is the property of Predesign or its 
    licensors and is protected by copyright, trademark, and other 
    intellectual property laws. You may not copy, modify, distribute, 
    or display any content from Predesign without our prior written consent.

    `,
  },
  {
    id: 22,
    title: "Acceptance of Terms",
    description: `By using the Predesign website and services, you agree to be bound by these terms and conditions.
                  If you do not agree to these terms, you may not use the Predesign platform.`,
  },
  {
    id: 2,
    title: "Changes to Terms",
    description: `Predesign reserves the right to change these terms and conditions at any time,
                  and it is your responsibility to review the terms for any updates or changes.
                  Your continued use of the Predesign platform after any changes to the terms have
                  been made constitutes your acceptance of the updated terms.`,
  },
  {
    id: 11,
    title: "Disclaimer of Warranties",
    description: `Predesign provides its app and website “as is”
     and makes no warranties or representations about the accuracy, 
     reliability, completeness, or timeliness of the content, software, 
     text, graphics, links, or other items on our app and website.`,
  },
];

export default terms;
