import React, { useState } from "react";
import { Box, Container, Typography, styled } from "@mui/material";
import { Link } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import * as Yup from "yup";

// custom imports

// components
import AppAlert from "../components/AppAlert";
import FormInput from "../forms/FormInput";

// api
import usersApi from "../api/users";

// utilities
import { handleError } from "../utilities/apiActivity";
import { Helmet } from "react-helmet-async";

const Form = styled(Box)(() => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
  rowGap: "10px",
}));

const Screen = styled(Container)(() => ({
  alignItems: "center",
  display: "flex",
  flex: 1,
  justifyContent: "center",
  padding: "10px",
}));

const StyledBox = styled(Box)(({ theme }) => ({
  alignItems: "center",
  borderRadius: theme.shape.borderRadius,
  display: "flex",
  flex: 1,
  flexDirection: "column",
  justifyContent: "center",
  maxWidth: "400px",
  overflow: "hidden",
  padding: "10px",
  width: "100%",
}));

const SignupButton = styled(Link)(() => ({
  color: "blue",
  fontSize: "1rem",
  marginBottom: "20px",
  marginTop: "20px",
  textAlign: "center",
  textDecoration: "none",
}));

const Title = styled(Typography)(() => ({
  fontSize: "1rem",
  fontWeight: "400",
  marginBottom: "30px",
  textAlign: "center",
}));

function ForgotPassword() {
  const [sendingRequest, setSendingRequest] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
  });

  const hideAlert = () => setAlert({ open: false, message: "" });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required().label("Email Address"),
    }),
    onSubmit: (values) => {
      sendPasswordResetToken(values);
    },
    validateOnBlur: false,
  });

  const sendPasswordResetToken = async (values) => {
    setSendingRequest(true);
    try {
      await usersApi.getForgotPasswordToken(values);
      setSendingRequest(false);
      formik.resetForm();
      return setAlert({
        open: true,
        message: "Password reset token has been sent to your email address.",
      });
    } catch (error) {
      handleError(error, () => setSendingRequest(false), setAlert);
    }
  };

  return (
    <>
      <Helmet>
        <title>CashYog - Forgot Password</title>
      </Helmet>
      <Screen>
        <StyledBox>
          <Title gutterBottom variant="h5">
            Enter account email address you want to recover.
          </Title>
          <Form component={"form"} onSubmit={formik.handleSubmit}>
            <FormInput
              formik={formik}
              name="email"
              type="email"
              label="Email Address"
            />
            <LoadingButton
              loading={sendingRequest}
              size="small"
              disableElevation
              type="submit"
              variant="contained"
              sx={{ width: "40%" }}
              onClick={formik.handleSubmit}
            >
              Submit
            </LoadingButton>
          </Form>
          <SignupButton to="/login" variant="link">
            Back to Login
          </SignupButton>
        </StyledBox>
      </Screen>
      <AppAlert alert={alert} onClose={hideAlert} />
    </>
  );
}

export default ForgotPassword;
