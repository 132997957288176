import React from "react";
import Select from "react-select";
import { Typography, styled, Box, useTheme } from "@mui/material";

const Container = styled(Box)(({ theme }) => ({
  // maxWidth: "300px",
}));

const Label = styled(Typography)(({ theme }) => ({
  display: "block",
  fontSize: "0.75rem",
  marginTop: "4px",
  marginRight: "14px",
  marginBottom: 0,
  marginLeft: "14px",
  color: "#d32f2f",
  fontWeight: 400,
  letterSpacing: "0.0333em",
}));

function FormSelect(props) {
  const theme = useTheme();
  const {
    label,
    name,
    formik,
    style,
    options,
    isMulti,
    isDisabled,
    controlStyle,
  } = props;

  return (
    <Container sx={style}>
      <Select
        key={formik.values[name]}
        isDisabled={isDisabled}
        isMulti={isMulti}
        placeholder={label}
        name={name}
        defaultValue={
          formik.values[name] || formik.values[name] === 0
            ? isMulti
              ? () => {
                  let array = [];
                  formik.values[name].map((c) => {
                    let newObject = { label: c, value: c };
                    return array.push(newObject);
                  });
                  return array;
                }
              : {
                  label: formik.values[name],
                  value: formik.values[name],
                }
            : ""
        }
        onChange={(change) => {
          if (!isMulti) {
            return formik.setFieldValue(name, change.value);
          }

          let newArray = [];
          change.map((c) => {
            return newArray.push(c.value);
          });
          return formik.setFieldValue(name, newArray);
        }}
        onBlur={() => formik.setFieldTouched(name, true)}
        styles={{
          container: (provided, state) => ({
            ...provided,
            marginBottom: "2px",
          }),
          menu: (provided, state) => ({
            ...provided,
            zIndex: 20,
          }),

          control: (provided, state) => ({
            ...provided,
            ...controlStyle,
            borderRadius: theme.shape.borderRadius,
            borderColor:
              formik.touched[name] && formik.errors[name]
                ? "#d32f2f"
                : provided.borderColor,
          }),
        }}
        options={options}
        theme={(theme) => ({
          ...theme,

          colors: {
            ...theme.colors,

            primary: "#2E16E6",
          },
        })}
      />
      {formik.touched[name] ? (
        <Label variant="h6" component={"label"}>
          {formik.errors[name]}
        </Label>
      ) : null}
    </Container>
  );
}

export default FormSelect;
