export const personaBackground = [
  // {
  //   label: "E-commerce",
  //   value: "E-commerce",
  // },
  {
    label: "Student",
    value: "Student",
  },
  {
    label: "Stay-at-Home Parent",
    value: "Stay-at-Home Parent",
  },
  {
    label: "Retiree",
    value: "Retiree",
  },
  {
    label: "Freelancer",
    value: "Freelancer",
  },
  {
    label: "Entrepreneur",
    value: "Entrepreneur",
  },
  {
    label: "Healtcare Professional",
    value: "Healtcare Professional",
  },
  {
    label: "Educator",
    value: "Educator",
  },
  {
    label: "Athlete",
    value: "Athlete",
  },
  {
    label: "Artist",
    value: "Artist",
  },
  {
    label: "Treveler",
    value: "Treveler",
  },
  {
    label: "Tech Enthusiast",
    value: "Tech Enthusiast",
  },
  {
    label: "Information and Technology",
    value: "Information and Technology",
  },
  {
    label: "Government",
    value: "Government",
  },
  {
    label: "Entertainment",
    value: "Entertainment",
  },
  {
    label: "Real Estate",
    value: "Real Estate",
  },
  {
    label: "History",
    value: "History",
  },
  {
    label: "Nature",
    value: "Nature",
  },
  {
    label: "Science and Technology",
    value: "Science and Technology",
  },
  {
    label: "Art and Craft",
    value: "Art and Craft",
  },
  {
    label: "Mechanical",
    value: "Mechanical",
  },
  {
    label: "Machinery",
    value: "Machinery",
  },
  {
    label: "Aviation",
    value: "Aviation",
  },
];

export const roleOfPersona = [
  {
    label: "Customer",
    value: "Customer",
  },
  {
    label: "Business Executive",
    value: "Business Executive",
  },
  {
    label: "Sales Representative",
    value: "Sales Representative",
  },
  {
    label: "Customer Service Representative",
    value: "Customer Service Representative",
  },
  {
    label: "Marketing Specialist",
    value: "Marketing Specialist",
  },
  {
    label: "Engineer",
    value: "Engineer",
  },
  {
    label: "Developer",
    value: "Developer",
  },
  {
    label: "Data Analyst",
    value: "Data Analyst",
  },
  {
    label: "Human Resource Professional",
    value: "Human Resource Professional",
  },
  {
    label: "Finance Professional",
    value: "Finance Professional",
  },
  {
    label: "Project Manager",
    value: "Project Manager",
  },
  {
    label: "Buyer",
    value: "Buyer",
  },
  {
    label: "Designer",
    value: "Designer",
  },
  {
    label: "Homemaker",
    value: "Homemaker",
  },
  {
    label: "Agent",
    value: "Agent",
  },
  {
    label: "Admin",
    value: "Admin",
  },
  {
    label: "Employee",
    value: "Employee",
  },
];

export const genders = [
  {
    label: "Male",
    value: "Male",
  },
  {
    label: "Female",
    value: "Female",
  },
];

export const maritalStatus = [
  {
    label: "Single",
    value: "Single",
  },
  {
    label: "Married",
    value: "Married",
  },
  {
    label: "Seperated",
    value: "Seperated",
  },
  {
    label: "Divorced",
    value: "Divorced",
  },
  {
    label: "Widowed",
    value: "Widowed",
  },
  {
    label: "Domestic Partnership",
    value: "Domestic Partnership",
  },
];

export const familyStatus = [
  {
    label: "Nuclear Family",
    value: "Nuclear Family",
  },
  {
    label: "Extended Family",
    value: "Extended Family",
  },
  {
    label: "Blended Family",
    value: "Blended Family",
  },
  {
    label: "Single-Parent Family",
    value: "Single-Parent Family",
  },
  {
    label: "Child-Free Family",
    value: "Child-Free Family",
  },
  {
    label: "Foster Family",
    value: "Foster Family",
  },
  {
    label: "Same-Sex Family",
    value: "Same-Sex Family",
  },
];

export const ethnicity = [
  {
    label: "Asian",
    value: "Asian",
  },
  {
    label: "Black/African American",
    value: "Black/African American",
  },
  {
    label: "Hispanic/Latinx",
    value: "Hispanic/Latinx",
  },
  {
    label: "Native American/American Indian",
    value: "Native American/American Indian",
  },
  {
    label: "White/Caucasian",
    value: "White/Caucasian",
  },
  {
    label: "Pacific Islander",
    value: "Pacific Islander",
  },
];

export const countries = [
  { label: "Norway", value: "Norway" },
  { label: "Ireland", value: "Ireland" },
  { label: "India", value: "India" },
  { label: "Switzerland", value: "Switzerland" },
  { label: "Hong Kong, China (SAR)", value: "Hong Kong, China (SAR)" },
  { label: "Iceland", value: "Iceland" },
  { label: "Germany", value: "Germany" },
  { label: "Sweden", value: "Sweden" },
  { label: "Australia", value: "Australia" },
  { label: "Netherlands", value: "Netherlands" },
  { label: "Denmark", value: "Denmark" },
  { label: "Finland", value: "Finland" },
  { label: "Singapore", value: "Singapore" },
  { label: "United Kingdom", value: "United Kingdom" },
  { label: "Canada", value: "Canada" },
  { label: "New Zealand", value: "New Zealand" },
  { label: "United States", value: "United States" },
  { label: "Liechtenstein", value: "Liechtenstein" },
  { label: "Japan", value: "Japan" },
  { label: "South Korea", value: "South Korea" },
  { label: "Israel", value: "Israel" },
];
