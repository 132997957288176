import React, { useState, useEffect } from "react";
import {
  styled,
  Container,
  Box,
  Typography,
  Button,
  Stack,
  IconButton,
  Dialog,
  DialogActions,
  useTheme,
  useMediaQuery,
} from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { WhatsappShareButton, WhatsappIcon } from "react-share";

import personasApi from "../api/personas";
import { handleError } from "../utilities/apiActivity";
import SplashScreen from "../components/SplashScreen";
import { Helmet } from "react-helmet-async";
import AppAlert from "../components/AppAlert";
import PersonalDetails from "../components/PersonalDetails";
import { useNavigate } from "react-router-dom";

const Wrapper = styled(Container)(() => ({
  display: "flex",
  flexDirection: "column",
  marginTop: 50,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.common.black,
    fontFamily: "Lato, sans-serif ",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  fontFamily: "Lato, sans-serif ",
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.common.white,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function SavedPersonas() {
  const navigate = useNavigate();
  const [fetchingPersonas, setFetchingPersonas] = useState(false);
  const [personas, setPersonas] = useState([]);
  const [deleting, setDeleting] = useState(false);

  const [alert, setAlert] = useState({
    open: false,
    message: "",
  });

  const [openDialog, setOpenDialog] = useState({
    persona: null,
    open: false,
  });

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const closeDialog = () => setOpenDialog({ open: false, persona: null });

  const handleHideAlert = () => setAlert({ ...alert, open: false });

  const getAllMyPersonas = async () => {
    setFetchingPersonas(true);
    try {
      const { data } = await personasApi.fetchAllPersonas();
      setPersonas(data);
      setFetchingPersonas(false);
    } catch (error) {
      handleError(error, () => setFetchingPersonas(false), setAlert);
    }
  };

  useEffect(() => {
    getAllMyPersonas();
  }, []);

  const handleDeletePersonClick = async (id) => {
    setDeleting(true);
    let originalList = personas;
    let newPersonaList = personas.filter((p) => p._id !== id);
    setPersonas(newPersonaList);
    try {
      await personasApi.deletePersona(id);
      setDeleting(false);
    } catch (error) {
      handleError(
        error,
        () => {
          setPersonas(originalList);
          setDeleting(false);
        },
        setAlert
      );
    }
  };

  const handleViewPersonaDetailsClick = (data) => {
    setOpenDialog({ open: true, persona: data });
  };

  const handleCreateNewPersonClick = () => navigate("/persona_generator");

  if (fetchingPersonas) return <SplashScreen />;
  return (
    <>
      <Helmet>
        <title>Predesign - Saved Personas</title>
      </Helmet>
      <Wrapper>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "5px 10px",
            marginBottom: "20px",
          }}
        >
          <Typography sx={{ fontWeight: "bolder" }}>Saved Persona</Typography>
          <Button
            variant="contained"
            size="small"
            disableElevation
            onClick={handleCreateNewPersonClick}
          >
            Generate New Persona
          </Button>
        </Box>
        {!personas.length ? (
          <Typography
            sx={{ textAlign: "center", fontSize: "16px", fontWeight: "bold" }}
          >
            No persona created yet!
          </Typography>
        ) : (
          <TableContainer
            sx={{ borderRadius: "5px", marginBottom: "60px" }}
            component={Paper}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">S.No.</StyledTableCell>
                  <StyledTableCell align="center">
                    Persona Background
                  </StyledTableCell>
                  <StyledTableCell align="center">Persona Role</StyledTableCell>
                  <StyledTableCell align="center">Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {personas.map((row) => (
                  <StyledTableRow key={row._id}>
                    <StyledTableCell align="center" component="th" scope="row">
                      {personas.findIndex((p) => p._id === row._id) + 1}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.data.background}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.data.role}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Stack
                        sx={{
                          alignSelf: "center",
                          display: "flex",
                          justifyContent: "center",
                        }}
                        direction={"row"}
                      >
                        <IconButton
                          disabled={deleting}
                          onClick={() =>
                            handleViewPersonaDetailsClick(row.data)
                          }
                        >
                          <RemoveRedEyeRoundedIcon
                            fontSize="small"
                            color="info"
                          />
                        </IconButton>
                        <IconButton
                          disabled={deleting}
                          onClick={() => handleDeletePersonClick(row._id)}
                        >
                          <DeleteRoundedIcon fontSize="small" color="error" />
                        </IconButton>
                      </Stack>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Dialog
          disableEscapeKeyDown={true}
          onClose={(event, reason) =>
            reason === "backdropClick" ? () => {} : closeDialog
          }
          fullScreen={fullScreen}
          open={openDialog.open}
          aria-labelledby="see-persona-details"
        >
          <Box sx={{ padding: "5px" }}>
            {openDialog.persona ? (
              <PersonalDetails persona={openDialog.persona} />
            ) : null}
          </Box>
          <DialogActions>
            <Button
              variant="contained"
              autoFocus
              size="small"
              onClick={closeDialog}
            >
              <CloseRoundedIcon fontSize="small" sx={{ mr: "2px" }} /> Close
            </Button>
            <WhatsappShareButton
              title={`Hi check out this AI persona generator which does the work of hours in minutes.`}
              url={`https://predesign.in`}
              separator={"  "}
              style={{
                backgroundColor: "#2E16E6",
                color: "white",
                textTransform: "uppercase",
                fontSize: "10px",
                fontFamily: "Lato, sans-serif",
                display: "flex",
                alignItems: "center",
                padding: "6px 12px",
                borderRadius: "5px",
                columnGap: "2px",
              }}
            >
              <WhatsappIcon round size={20} /> Share
            </WhatsappShareButton>
          </DialogActions>
        </Dialog>
      </Wrapper>
      <AppAlert alert={alert} onClose={handleHideAlert} />
    </>
  );
}
