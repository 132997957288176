import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import useAuth from "./auth/useAuth";

export const RequireAuth = ({ children }) => {
  let { user } = useAuth();
  let location = useLocation();

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else {
    return children;
  }
};
