export const handleError = (error, fn, setAlert = () => {}) => {
  fn();
  if (error.response) {
    return setAlert({
      message: error.response.data.message
        ? error.response.data.message
        : "Connection failure! Please try again.",
      type: "error",
      open: true,
    });
  }
  if (error.message) {
    return setAlert({
      message: error.message
        ? error.message
        : "Connection failure! Please try again.",
      type: "error",
      open: true,
    });
  }

  setAlert({
    message: "Something went wrong! Please try again.",
    type: "error",
    open: true,
  });
};
