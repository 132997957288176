import React from "react";
import { Typography, Box, styled } from "@mui/material";

const Wrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  padding: "50px 20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const Title = styled(Typography)(() => ({
  color: "white",
  fontSize: "32px",
  textAlign: "center",
}));
const SubTitle = styled(Typography)(() => ({
  color: "white",
  fontSize: "14px",
  textAlign: "center",
}));

export default function PageHeader({ title = "", subTitle = "" }) {
  return (
    <Wrapper>
      <Title component="h1">{title}</Title>
      {subTitle ? <SubTitle component="h2">{subTitle}</SubTitle> : null}
    </Wrapper>
  );
}
