import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  styled,
  Typography,
  Box,
  Button,
  IconButton,
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemButton,
  Menu,
  MenuItem,
  Avatar,
  ListItemIcon,
} from "@mui/material";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
// import HandymanRoundedIcon from "@mui/icons-material/HandymanRounded";
import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import Person2RoundedIcon from "@mui/icons-material/Person2Rounded";

import useAuth from "../auth/useAuth";
import { useLocation, useNavigate } from "react-router-dom";
import { Logout } from "@mui/icons-material";
import usersApi from "../api/users";
import { handleError } from "../utilities/apiActivity";
import AppAlert from "./AppAlert";
import AppImage from "./AppImage";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  position: "sticky",
  boxShadow: theme.shadows[2],
  padding: "0 5px",
  backgroundColor: "white",
  [theme.breakpoints.down("sm")]: {
    padding: "0 5px",
  },
}));

const StyledToolbar = styled(Toolbar)(() => ({
  display: "flex",
  justifyContent: "space-between",
  backgroundColor: "white",
  alignItems: "center",
}));

const NavLinksContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  flex: 1,
  maxWidth: "500px",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const NavLink = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  textAlign: "center",
  color: "black",
  fontFamily: "Lato",
  fontWeight: "400",
  cursor: "pointer",
  ":hover": {
    color: theme.palette.primary.main,
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.down("md")]: {
    display: "flex",
  },
}));

export default function Navbar() {
  const { user, setUser } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const anchor = "bottom";
  const [state, setState] = useState({
    bottom: false,
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [alert, setAlert] = useState({
    message: "",
    open: false,
  });

  const hideAlert = () => setAlert((alert) => ({ ...alert, open: false }));

  const handleEmailClick = (e) => {
    e.preventDefault();
    window.open(
      `mailto:support@predesign.in?subject="I want to support this project"&body=Hi`
    );
  };

  const Links = [
    // {
    //   id: 1,
    //   name: "Tools",
    //   action: () => navigate("/tools", { replace: true }),
    //   path: "/tools",
    // },
    //   {
    //     id: 2,
    //     name: "Pricing",
    //     action: () => {},
    //   },
    {
      id: 3,
      name: "Support",
      action: handleEmailClick,
      path: "/support_project",
    },
    {
      id: 4,
      name: "Privacy Policy",
      action: () => navigate("/privacy_policy", { replace: true }),
      path: "/privacy_policy",
    },
    {
      id: 5,
      name: "Terms of Service",
      action: () => navigate("/terms_of_service", { replace: true }),
      path: "/terms_of_service",
    },
  ];

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
        padding: "20px 10px",
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {Links.map((item, index) => (
          <ListItem sx={{ marginBottom: "5px" }} key={item.id} disablePadding>
            <ListItemButton
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
              onClick={item.action}
            >
              <NavLink>{item.name}</NavLink>
            </ListItemButton>
          </ListItem>
        ))}
        <Divider />
        {!user ? (
          <Button
            sx={{ alignSelf: "center", margin: "10px 0 0 0" }}
            size="small"
            onClick={handleLoginClick}
            disableElevation
            variant="contained"
          >
            Login
          </Button>
        ) : null}
      </List>
    </Box>
  );

  const handleLoginClick = () => navigate("/login", { replace: true });

  const goToProfile = () =>
    navigate("/profile", { replace: pathname === "/profile" ? true : false });

  // const handleSeeTools = () =>
  //   navigate("/tools", { replace: pathname === "/tools" ? true : false });

  const handleSeeSavedItems = () =>
    navigate("/saved", { replace: pathname === "/saved" ? true : false });

  const handleLogoClick = () => navigate("/", { replace: true });

  const handleLogout = async () => {
    try {
      await usersApi.logoutUser();
      setUser(null);
    } catch (error) {
      handleError(error, () => {}, setAlert);
    }
  };

  return (
    <StyledAppBar>
      <StyledToolbar>
        <AppImage
          onClick={handleLogoClick}
          style={{ width: "120px", cursor: "pointer" }}
          defaultImage={require("../assets/navlogo.png")}
        />
        {!user ? (
          <>
            <NavLinksContainer>
              {Links.map((l) => (
                <NavLink
                  sx={{
                    color:
                      pathname === l.path
                        ? (theme) => theme.palette.primary.main
                        : "black",
                    fontWeight: pathname === l.path ? "bold" : "400",
                  }}
                  key={l.id}
                  onClick={l.action}
                >
                  {l.name}
                </NavLink>
              ))}

              {!user ? (
                <Button
                  size="small"
                  onClick={handleLoginClick}
                  variant="contained"
                >
                  Login
                </Button>
              ) : (
                <Button size="small" onClick={goToProfile} variant="contained">
                  Profile
                </Button>
              )}
            </NavLinksContainer>
            <StyledIconButton onClick={toggleDrawer(anchor, true)} size="small">
              <MenuRoundedIcon fontSize="small" />
            </StyledIconButton>
          </>
        ) : (
          <Button
            variant="outlined"
            onClick={handleClick}
            size="small"
            sx={{ ml: 2, display: "flex", columnGap: "5px" }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar
              src={user ? user.avatar_url : ""}
              sx={{ width: 20, height: 20 }}
            />{" "}
            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
              {user.firstName}
            </Typography>
          </Button>
        )}
      </StyledToolbar>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          sx={{
            color: pathname === "/profile" ? "#2E16E6" : "black",
          }}
          onClick={goToProfile}
        >
          <ListItemIcon sx={{ paddingTop: "3px" }}>
            <Person2RoundedIcon fontSize="medium" />
          </ListItemIcon>
          Profile
        </MenuItem>
        {/* <MenuItem
          sx={{
            color: pathname === "/tools" ? "#2E16E6" : "black",
          }}
          onClick={handleSeeTools}
        >
          <ListItemIcon sx={{ paddingTop: "5px" }}>
            <HandymanRoundedIcon fontSize="medium" />
          </ListItemIcon>
          Tools
        </MenuItem> */}

        <MenuItem
          sx={{
            color: pathname === "/saved" ? "#2E16E6" : "black",
          }}
          onClick={handleSeeSavedItems}
        >
          <ListItemIcon sx={{ paddingTop: "5px" }}>
            <FavoriteBorderRoundedIcon fontSize="medium" />
          </ListItemIcon>
          Saved Items
        </MenuItem>
        <MenuItem onClick={handleEmailClick}>
          <ListItemIcon sx={{ paddingTop: "5px" }}>
            <EmailRoundedIcon fontSize="medium" />
          </ListItemIcon>
          Mail to Support
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon sx={{ paddingTop: "5px" }}>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
      <Drawer
        anchor={anchor}
        open={state[anchor]}
        onClose={toggleDrawer(anchor, false)}
      >
        {list(anchor)}
      </Drawer>
      <AppAlert alert={alert} onClose={hideAlert} />
    </StyledAppBar>
  );
}
