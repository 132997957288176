import { Card, CardContent, Typography } from "@mui/material";
import React from "react";

export default function Cards({
  icon,
  Icon,
  title,
  info,
  color = "secondary",
}) {
  return (
    <Card
      sx={{
        maxWidth: 250,
        display: "flex",
        flexDirection: "column",
        paddingTop: "10px",
        backgroundColor: (theme) =>
          color === "primary" ? "#c0b8f8" : theme.palette.secondary.main,
      }}
    >
      {icon ? Icon : null}
      <CardContent>
        <Typography
          sx={{ textAlign: "center", fontSize: "18px", fontWeight: "bold" }}
          gutterBottom
          variant="h5"
          component="div"
        >
          {title}
        </Typography>
        <Typography
          sx={{ textAlign: "center" }}
          variant="body2"
          color="text.secondary"
        >
          {info}
        </Typography>
      </CardContent>
    </Card>
  );
}
