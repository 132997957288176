import React from "react";
import { Box, Typography, styled, Grid } from "@mui/material";
import AppImage from "./AppImage";

const Wrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  padding: "5px",
}));

const GridItemContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  rowGap: "5px",
}));

const ItemInfo = styled(Typography)(() => ({
  fontSize: "14px",
  fontWeight: "bold",
  color: "black",
}));
const ItemValue = styled(Typography)(() => ({
  fontSize: "14px",
  color: "grey",
}));

export default function PersonalDetails({ persona }) {
  const filteredKeys = Object.keys(persona).filter(
    (key) => key !== "role" && key !== "background" && key !== "imageUrl"
  );
  return (
    <Wrapper>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={5}>
          <AppImage
            imageUrl={persona.imageUrl}
            defaultImage={require("../assets/defaultPersonaImage.jpg")}
          />
        </Grid>
        <Grid item xs={12} sm={7}>
          <GridItemContainer>
            {filteredKeys.map((key) => (
              <ItemInfo key={key}>
                {key} : <ItemValue component="span">{persona[key]}</ItemValue>
              </ItemInfo>
            ))}
          </GridItemContainer>
        </Grid>
      </Grid>
    </Wrapper>
  );
}
