import { useContext } from "react";

import AuthContext from "./context";

const useAuth = () => {
  const { user, setUser } = useContext(AuthContext);

  const logIn = (user) => {
    setUser(user);
  };

  return {
    user,
    setUser,
    logIn,
  };
};

export default useAuth;
