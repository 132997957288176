import React from "react";
import { Typography, styled } from "@mui/material";

const StyledText = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  color: "black",
  fontWeight: "bold",
  textAlign: "center",
}));

export default function SectionSubTitle({ children, styles = {} }) {
  return <StyledText sx={styles}>{children}</StyledText>;
}
