const policy = [
  {
    id: 1,
    title: "What information do we collect?",
    description: `When you use Predesign, we may collect personal
     information such as your name, email address, and Google account 
     information if you choose to sign up with Google. We may also 
     collect data on how you use our app and website.`,
  },
  {
    id: 2,
    title: "How We Use Your Information?",
    description: `We use your personal information to provide and
     improve our app and website, communicate with you about our services,
      and personalize your experience. We may also use your information 
      to send you marketing communications if you have opted in to 
      receive them.`,
  },
  {
    id: 3,
    title: "Sharing Your Information",
    description: `We may share your personal information with our
     service providers who help us operate our app and website. 
     We may also share your information if required by law or in
      connection with a merger or acquisition.

    `,
  },
  {
    id: 4,
    title: "How do we protect your information?",
    description: `We take appropriate security measures to protect 
    your personal information from unauthorized access, alteration, 
    disclosure, or destruction.`,
  },
  {
    id: 5,
    title: "Cookies",
    description: `We may use cookies or other tracking technologies to collect information
       about your use of the Predesign platform and to customize your experience. 
       You can disable cookies or adjust your cookie settings through your browser settings.`,
  },
  {
    id: 6,
    title: "Children's Privacy",
    description: `The Predesign platform is not intended for children under the age of 13.
       We do not knowingly collect personal information from children under the age of 13.`,
  },
  {
    id: 7,
    title: "Changes to This Privacy Policy",
    description: `We may update this privacy policy from time to time to reflect changes
       to our practices or to comply with legal requirements. Any updates to 
       this privacy policy will be posted on this page, and we encourage you to review the privacy policy regularly.`,
  },
  {
    id: 8,
    title: "Contact Us",
    description:
      "If you have any questions or concerns about our privacy policy, please contact us at support@predesign.in.",
  },
];

export default policy;
