import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { styled } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import AppImage from "./AppImage";
//import { useLocation, useNavigate } from "react-router-dom";

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: theme.palette.primary.main,
  alignItems: "center",
  padding: "60px 10px 50px 10px",
}));

const NavLinksContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  flex: 1,
  gap: "10px",
  maxWidth: "600px",
  flexWrap: "wrap",
  width: "100%",
  margin: "60px 0 20px 0",
}));

const NavLink = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  textAlign: "center",
  color: "white",
  fontWeight: "400",
  cursor: "pointer",
  ":hover": {
    color: theme.palette.secondary.light,
  },
}));

const CopyRightText = styled(Typography)(() => ({
  color: "white",
  textAlign: "center",
  marginTop: "20px",
  fontSize: "0.8rem",
}));

export default function Footer() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleEmailClick = (e) => {
    e.preventDefault();
    window.open(
      `mailto:support@predesign.in?subject="I want to support this project"&body=Hi`
    );
  };

  const Links = [
    {
      id: 1,
      name: "Home",
      action: () => navigate("/", { replace: true }),
      path: "/",
    },
    // {
    //   id: 2,
    //   name: "Tools",
    //   action: () => navigate("/tools", { replace: true }),
    //   path: "/tools",
    // },
    {
      id: 3,
      name: "Support",
      action: handleEmailClick,
      path: "/support_project",
    },
    // {
    //   id: 4,
    //   name: "Login",
    //   action: () => navigate("/login", { replace: true }),
    //   path: "/login",
    // },
    // {
    //   id: 5,
    //   name: "Sign Up",
    //   action: () => navigate("/signup", { replace: true }),
    //   path: "/signup",
    // },
    {
      id: 6,
      name: "Privacy Policy",
      action: () => navigate("/privacy_policy", { replace: true }),
      path: "/privacy_policy",
    },
    {
      id: 7,
      name: "Terms of Service",
      action: () => navigate("/terms_of_service", { replace: true }),
      path: "/terms_of_service",
    },
  ];

  return (
    <Wrapper>
      <AppImage
        style={{ maxWidth: "250px" }}
        defaultImage={require("../assets/footerlogo.png")}
      />
      <NavLinksContainer>
        {Links.map((l) => (
          <NavLink
            sx={{
              color:
                pathname === l.path
                  ? (theme) => theme.palette.secondary.main
                  : "white",
              fontWeight: pathname === l.path ? "bold" : "400",
            }}
            key={l.id}
            onClick={l.action}
          >
            {l.name}
          </NavLink>
        ))}
      </NavLinksContainer>

      <CopyRightText>
        Predesign &#169; {new Date().getFullYear()} All rights reserved.
      </CopyRightText>
    </Wrapper>
  );
}
