import React from "react";
import Hero from "../components/Hero";
import { styled, Box } from "@mui/material";
import SectionTitle from "../components/Section/SectionTitle";
import SectionSubTitle from "../components/Section/SectionSubTitle";
import Cards from "../components/Section/Cards";

import LoginRoundedIcon from "@mui/icons-material/LoginRounded";
import OutputRoundedIcon from "@mui/icons-material/OutputRounded";
import PsychologyRoundedIcon from "@mui/icons-material/PsychologyRounded";
import Person2RoundedIcon from "@mui/icons-material/Person2Rounded";
import SpeedRoundedIcon from "@mui/icons-material/SpeedRounded";
import TaskRoundedIcon from "@mui/icons-material/TaskRounded";
import ScrollUp from "../components/ScrollUp";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import VerifyCredential from "../components/VerifyCredential";

import useAuth from "../auth/useAuth";
import usersApi from "../api/users";

const Section = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  marginBottom: "50px",
  maxWidth: "1200px",
  alignSelf: "center",
}));

const CardsContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-around",
  flexWrap: "wrap",
  rowGap: "30px",
  columnGap: "30px",
}));

const sectionOneData = [
  {
    id: 1,
    title: "Sign Up",
    info: "Select tool from available list of tools based on your need.",
    icon: true,
    Icon: (
      <Person2RoundedIcon sx={{ fontSize: "150px", alignSelf: "center" }} />
    ),
  },
  {
    id: 2,
    title: "Enter required details",
    info: "Enter the asked details as per your project neccessity",
    icon: true,
    Icon: <LoginRoundedIcon sx={{ fontSize: "150px", alignSelf: "center" }} />,
  },
  {
    id: 3,
    title: "Generate Output",
    info: "Get AI generated output for respective entries",
    icon: true,
    Icon: <OutputRoundedIcon sx={{ fontSize: "150px", alignSelf: "center" }} />,
  },
];
const sectionTwoData = [
  {
    id: 1,
    title: "Powered by AI",
    info: "Powered by Advanced GPT-4 language Model",
    icon: true,
    Icon: (
      <PsychologyRoundedIcon sx={{ fontSize: "150px", alignSelf: "center" }} />
    ),
  },
  {
    id: 2,
    title: "Quick Result",
    info: "No research required to generate UX Persona for your project",
    icon: true,
    Icon: <SpeedRoundedIcon sx={{ fontSize: "150px", alignSelf: "center" }} />,
  },
  {
    id: 3,
    title: "Standard Output",
    info: "Get optimized output to uplift your ideation standard",
    icon: true,
    Icon: <TaskRoundedIcon sx={{ fontSize: "150px", alignSelf: "center" }} />,
  },
];

export default function Home() {
  const navigate = useNavigate();
  const { user, setUser } = useAuth();

  const emailGetToken = () => usersApi.getEmailToken();
  const emailVerifyFunction = (data) => usersApi.verifyEmailToken(data);

  const handleUpdateEmailVerified = (data) => {
    setUser(data);
  };

  const handleStartGeneratingButtonClick = () => navigate("/persona_generator");
  return (
    <>
      <Helmet>
        <title>Predesign</title>
        <meta
          name="description"
          content="Use AI to generate UX element for your new project and speed up your ideation phase."
        />
      </Helmet>
      {!user ? null : (
        <Box
          sx={{
            alignSelf: "center",
            padding: "10px 20px",
            display: user.verified.email ? "none" : "flex",
          }}
        >
          <VerifyCredential
            title="Verify Email"
            data={{
              toVerify: "Email",
              value: user.email,
              isVerified: user.verified.email,
            }}
            getTokenFunction={emailGetToken}
            verifyTokenFunction={emailVerifyFunction}
            onSuccesfullVerification={handleUpdateEmailVerified}
          />
        </Box>
      )}
      <Hero onButtonClick={handleStartGeneratingButtonClick} />
      <Section sx={{ marginTop: "50px" }}>
        <SectionTitle>START GENERATING IN 3 EASY STEPS</SectionTitle>
        <SectionSubTitle styles={{ marginBottom: "30px" }}>
          How it works?
        </SectionSubTitle>
        <CardsContainer>
          {sectionOneData.map((d) => (
            <Cards
              key={d.id}
              icon={d.icon}
              Icon={d.Icon}
              title={d.title}
              info={d.info}
            />
          ))}
        </CardsContainer>
      </Section>
      <Section sx={{ margin: "50px 0 100px 0" }}>
        <SectionTitle>
          INTEGRATED WITH ADVANCE AI MODEL TO FASTEN YOUR IDEATION
        </SectionTitle>
        <SectionSubTitle styles={{ marginBottom: "30px" }}>
          Generate unique UX Persona in seconds
        </SectionSubTitle>
        <CardsContainer>
          {sectionTwoData.map((d) => (
            <Cards
              icon={d.icon}
              Icon={d.Icon}
              color="primary"
              key={d.id}
              title={d.title}
              info={d.info}
            />
          ))}
        </CardsContainer>
      </Section>
      <ScrollUp />
    </>
  );
}
