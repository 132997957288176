import apiClient from "./apiClient";

const endPoint = "/api/personas";

const generatePersona = (data) =>
  apiClient.post(endPoint + "/generate/persona", data);

const savePersona = (data) => apiClient.post(endPoint + "/create/new", data);

const getPersonaDetails = (id) =>
  apiClient.get(endPoint + "/persona/details/" + id, {});

const deletePersona = (id) => apiClient.delete(endPoint + "/persona/" + id, {});

const fetchAllPersonas = () => apiClient.get(endPoint + "/my/all/personas", {});

const personasApi = {
  generatePersona,
  savePersona,
  getPersonaDetails,
  deletePersona,
  fetchAllPersonas,
};

export default personasApi;
